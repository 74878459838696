import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "left_tree_panel" }

import { treeEvent } from '@/types/utils'
  import { ref, getCurrentInstance, onMounted, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import merge from 'webpack-merge'
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'LeftTree',
  setup(__props) {

  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);
  const router = useRouter();
  const route = useRoute();

  let treeData = ref([]);
  let show = ref(false)
  let expandedKeys = ref([0])
  let selectedKeys = ref([parseInt((route.params as any).id || (route.params as any).pid)])

  watch(() => route.params.pid, (n: any, o) => {
    if(n && n != o && !route.params.id) {
      show.value = false;
      selectedKeys.value = [parseInt(n)]
      showPage()
    }
  })

  watch(() => route.params.od, (n: any, o) => {
    if(n && n != o) {
      show.value = false;
      selectedKeys.value = [parseInt(n)]
      showPage()
    }
  })

  onMounted(() => {
    showPage()
  })

  function showPage() {
    globalProperties.$API.keyIndustriesParentType({
      cateId: parseInt((route.params as any).pid)
    }).then((res: any) => {
      if(res.data.code == 200) {
        let ids = [] as any;
        res.data.data.filter((item: any) => { return item.parentId }).forEach((item: any) => { ids.push(item.parentId) })
        ids = Array.from(new Set(ids))
        treeData.value = treeEvent(res.data.data);
        expandedKeys.value = [...ids];
        show.value = true;
      }
    })
  }

  function selectClick(selectedKey: any, data: any) {
    if(data.node.type == 1) {
      window.open(data.node.externalLink)
    }else {
      selectedKeys.value = selectedKey;
      router.replace({
        params:merge(route.params, {'id': selectedKey[0] })
      })
    }
  }


return (_ctx: any,_cache: any) => {
  const _component_Icon_down = _resolveComponent("Icon-down")!
  const _component_icon_file = _resolveComponent("icon-file")!
  const _component_icon_launch = _resolveComponent("icon-launch")!
  const _component_a_tree = _resolveComponent("a-tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(show))
      ? (_openBlock(), _createBlock(_component_a_tree, {
          key: 0,
          "selected-keys": _unref(selectedKeys),
          blockNode: "",
          data: _unref(treeData),
          "show-line": true,
          "default-expanded-keys": _unref(expandedKeys),
          "default-selected-keys": _unref(selectedKeys),
          onSelect: selectClick
        }, {
          "switcher-icon": _withCtx((node, { isLeaf }) => [
            (!isLeaf)
              ? (_openBlock(), _createBlock(_component_Icon_down, { key: 0 }))
              : _createCommentVNode("", true),
            (isLeaf && node.type == 0 )
              ? (_openBlock(), _createBlock(_component_icon_file, { key: 1 }))
              : _createCommentVNode("", true),
            (isLeaf && node.type == 1 )
              ? (_openBlock(), _createBlock(_component_icon_launch, { key: 2 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["selected-keys", "data", "default-expanded-keys", "default-selected-keys"]))
      : _createCommentVNode("", true)
  ]))
}
}

})