import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "keyIndustriesList" }
const _hoisted_2 = { class: "right" }

import CommonTable from '@/components/CommonTable.vue';
import CommonPagination from '@/components/CommonPagination.vue';
import { onMounted, ref, getCurrentInstance, watch } from 'vue'
import { CONST } from '@/types/const'
import LeftTree from './components/LeftTree.vue';
import { useRoute } from 'vue-router';

  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      LeftTree,
      CommonTable,
      CommonPagination
    }
  },
  __name: 'List',
  setup(__props) {


  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);
  const route = useRoute();

  let tableData = ref([])
  let pageNum = ref(1)
  let pageSize = ref(15)
  let totalNum = ref(100)

  onMounted(() => {
    showPage()
  })

  watch(() => route.params.id, (n, o) => {
    if(n && n != o) {
      pageNum.value = 1;
      showPage()
    }
  })

  watch(() => route.params.pid, (n, o) => {
    if(n && n != o && ! route.params.id) {
      pageNum.value = 1;
      showPage()
    }
  })

  function showPage() {
    globalProperties.$API.keyIndustriesList({
      pageNum: pageNum.value,
      pageSize: pageSize.value,
      cateId: parseInt((route.params as any).id) || parseInt((route.params as any).pid)
    }).then((res: any) => {
      if(res.data.code == 200) {
        res.data.data.list.forEach((item: any) => {
          let data = (CONST as any).standardState.filter((line: any) => { 
            return line.value ==  item.standardState 
          })
          item.statusName = data[0].label;
          item.color = data[0].color;
          item.standard_number = item.standardNumber
          item.chinese_name = item.chineseName || item.englishName
          item.release_date = item.releaseDate ? item.releaseDate.split(' ')[0] : ''
          item.implement_date = item.implementDate ?  item.implementDate.split(' ')[0] : ''
        })
        tableData.value = res.data.data.list
        totalNum.value = res.data.data.total
      }else {
        tableData.value = []
        totalNum.value = 0
        globalProperties.$message.warning(res.data.msg)
      }
    })
  }


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LeftTree),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(CommonTable, {
        columns: (_unref(CONST) as any).standardTable.slice(0, -1),
        tableData: _unref(tableData)
      }, null, 8, ["columns", "tableData"]),
      _createVNode(CommonPagination, {
        pageNum: _unref(pageNum),
        pageSize: _unref(pageSize),
        totalNum: _unref(totalNum),
        onPageChange: _cache[0] || (_cache[0] = (page) => { _isRef(pageNum) //@ts-ignore
 ? pageNum.value = page : pageNum = page; showPage() }),
        onSizeChange: _cache[1] || (_cache[1] = (size) => { _isRef(pageNum) //@ts-ignore
 ? pageNum.value = 1 : pageNum = 1; _isRef(pageSize) //@ts-ignore
 ? pageSize.value = size : pageSize = size; showPage() })
      }, {
        left: _withCtx(() => [
          _createTextVNode(" 共" + _toDisplayString(_unref(totalNum)) + "条，每页" + _toDisplayString(_unref(pageSize)) + "条", 1)
        ]),
        _: 1
      }, 8, ["pageNum", "pageSize", "totalNum"])
    ])
  ]))
}
}

})